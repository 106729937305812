import React from 'react';
import InspirationLayout from '../../components/layouts/InspirationLayout';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Button from '../../components/Button';
import { ChevronRightIcon } from '@heroicons/react/solid';

const Inspiration = ({data}) => {

	const bannerImg1 = 'transport-banner.jpeg';
	const bannerImg2 = 'mikko-annala.jpeg';
	let bannerImgObj1;
	let bannerImgObj2;

	const inspirationBannerImgData = useStaticQuery(graphql`
	query inspirationBannerImageObjects {
		allFile(filter: {sourceInstanceName: {eq: "inspirationBanners"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							width: 1450, 
							placeholder: BLURRED, 
							formats: [AUTO, WEBP, AVIF])
					}
					relativePath
				}
			}
		}
	}
  `);

	for (let i = 0; i < inspirationBannerImgData.allFile.edges.length; i++) {
		if (inspirationBannerImgData.allFile.edges[i].node.relativePath === bannerImg1) {
			bannerImgObj1 = inspirationBannerImgData.allFile.edges[i].node;
		}
		else if (inspirationBannerImgData.allFile.edges[i].node.relativePath === bannerImg2) {
			bannerImgObj2 = inspirationBannerImgData.allFile.edges[i].node;
		}
	}
	

	const backgroundImg1 = getImage(bannerImgObj1);
	const bgImage1 = convertToBgImage(backgroundImg1);


	const backgroundImg2 = getImage(bannerImgObj2);
	const bgImage2 = convertToBgImage(backgroundImg2);

	return (
		<InspirationLayout>
			<main id="content" role="main">
				<div className="isolate bg-black border-b-5 border-yellow">
					<div className="container xl:h-half-screen lg:h-100 md:h-80 h-60 relative">
						<BackgroundImage className="w-full h-full relative bg-top bg-center bg-cover" Tag="div" {...bgImage1}>	
							<div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
							<div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
							<div className="grid grid-cols-1 absolute top-0 w-full h-full content-end z-10">
								<div className="pb-2">
									<h2 className="text-yellow uppercase lg:text-2xl md:text-xl text-base font-bold">Tobias Events</h2>
								</div>
								<div>
									<span className="bold text-white lg:text-xl md:text-base text-xs">Sydney Metro</span>
								</div>
								<div className="pb-2">
									<h4 className="uppercase text-white font-bold lg:text-xl md:text-base text-sm">Future of transport</h4>
								</div>
								<div className="pb-2">
									<h3 className="uppercase text-white lg:text-2xl md:text-xl text-base font-bold">Co-designing transport spaces of the future</h3>
								</div>
								<div className="pt-2 pb-4">
									<Button bgClassName={'focus:outline-none focus:ring-4 focus:ring-pink bg-yellow'} textClassName={'text-black'} link="/events/sydney-metro">Watch the video<ChevronRightIcon className="h-8 w-8 inline text-black" /></Button>
								</div>
							</div>
						</BackgroundImage>
          </div>
				</div>							

				<div className="isolate bg-black border-b-5 border-pink">
					<div className="container xl:h-half-screen lg:h-100 md:h-80 h-60 relative overflow-hidden">
						<BackgroundImage className="w-full h-full relative bg-top bg-center bg-cover" Tag="div" {...bgImage2}>	
							<div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
							<div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
							<div className="grid grid-cols-1 absolute top-0 w-full h-full content-end z-10">
								<div className="pb-2">
									<h2 className="text-pink uppercase lg:text-2xl md:text-xl text-base font-bold">Rebels</h2>
								</div>
								<div>
									<span className="bold text-white lg:text-xl md:text-base text-xs">Demos Helsinki</span>
								</div>
								<div className="pb-2">
									<h4 className="uppercase text-white font-bold lg:text-xl md:text-base text-sm">Mikko Annala</h4>
								</div>
								<div className="pb-2">
									<h3 className="uppercase text-white lg:text-2xl md:text-xl text-base font-bold">Why do governments need to innovate?</h3>
								</div>
								<div className="pt-2 pb-4">
									<Button bgClassName={'focus:outline-none focus:ring-4 focus:ring-yellow bg-pink'} textClassName={'text-white'} link="/rebels/mikko-annala">Watch interview<ChevronRightIcon className="h-8 w-8 inline text-white" /></Button>
								</div>
							</div>
						</BackgroundImage>
          </div>
				</div>				

			</main>
		</InspirationLayout>
	)
}

export default Inspiration;



